<app-generic-carousel
    [rowTitle]="'sch-membership.title'"
    [items]="items"
    [itemTemplate]="itemTemplate"
    [singleElement]="true"
>
    
</app-generic-carousel>
<ng-template #itemTemplate let-item>
    <div class="col">
        <!-- <img class="carousel-item-image" /> -->
        <div class="content-display" [ngClass]="{'isContent': item['isContent']}" [ngStyle]="{'background-image':item['img'], 'background-size': '100% 100%', 'background-repeat': 'no-repeat'}">
            <img *ngIf="item['isContent']" class="sch-logo" [src]="'../../assets/img/large-SCH_Vert_Logo_White_RGB.png'"/>
            <img *ngIf="item['isContent'] && isASTC()" class="astc-logo" [src]="'../../assets/img/ASTC_PASSPORT_WHITE.png'"/>

            <div *ngIf="item['isContent']" style="height: 100%">
                <div *ngIf="loginLoading" class="loadingBox">
                  <mat-progress-spinner
                  style="position: absolute;
                  left: 0; 
                  right: 0; 
                  margin-left: auto; 
                  margin-right: auto; top: 25px"
                    mode="indeterminate">
                  </mat-progress-spinner>
                </div>
                <div *ngIf="item['membershipId']" class="display-text">
                  <ion-button id="open-card-modal" expand="block" class="card-view-button" fill="clear" (click)="toggleModal('display', true)">
                    <div class="button-display">
                      <p >
                        ID: {{item['membershipId']}}
                      </p>
                      <p >
                        Name: {{item['fullname']}}
                      </p>
                      <p>
                        Level: {{item['passKind']}}
                      </p>
                    </div>
                  </ion-button>
                  <ion-modal [isOpen]="isDisplayModalOpen" class="card-modal" (willDismiss)="onWillDismiss($event)" [backdropDismiss]="false">
                    <ng-template>
                      <ion-header>
                        <ion-toolbar>
                          <ion-buttons slot="start">
                            <ion-button (click)="toggleModal('display', false)">Close</ion-button>
                          </ion-buttons>
                        </ion-toolbar>
                      </ion-header>
                      <ion-content class="ion-padding">
                        <div class="card-view-container">
                          <div class="membership-card" [ngClass]="{'astc': isASTC()}">
                            <img class="sch-logo-modal" [src]="'../../assets/img/large-SCH_Vert_Logo_White_RGB.png'"/>
                            <img *ngIf="isASTC()" class="astc-logo-modal" [src]="'../../assets/img/ASTC_PASSPORT_WHITE.png'"/>
                            <div class="card-owner-info">
                              <!-- <div class="header">
                                <span>{{'membership card' | uppercase}}</span>
                              </div> -->
                              <div *ngFor="let member of getMembersList()" class="member-listing">
                                <p class="member-name">{{member.first_name}} {{member.last_name}}</p>
                                <p class="member-info">{{member.visual_id_str}} <span *ngIf="member.primary">{{'primary user' | uppercase}}</span></p>
                              </div>
                            </div>
                          </div>
                          <div class="membership-info">
                            <div class="info-header">
                              <span class="membership_type-title">
                                {{'membership type' | uppercase}}
                              </span>
                              <span class="membership_exp-title">
                                {{'expiration date' | uppercase}}
                              </span>
                            </div>
                            <div class="info-content">
                              <span>{{memberData.pass_kind_name}}</span>
                              <span>{{getFormattedExpirationDate()}}</span>
                            </div>
                          </div>
                          <div class="qr-container">
                            <qrcode [qrdata]="memberData.visual_id_str" [width]="256" [level]="'M'"></qrcode>
                          </div>
                        </div>
 
                      </ion-content>
                      <ion-footer style="text-align: center;">
                        <ion-button shape="round" fill="outline" (click)="logout()">Logout</ion-button>
                      </ion-footer>
                    </ng-template>
                  </ion-modal>
                    <!-- <p >
                      ID: {{item['membershipId']}}
                    </p>
                    <p >
                        Name: {{item['fullname']}}
                    </p> -->
                </div>
              
                <div *ngIf="!item['membershipId']">
                    <ion-button id="open-modal" expand="block" class="login-button" *ngIf="!loginLoading" (click)="toggleModal('login', true)">Login</ion-button>
                    <ion-modal [isOpen]="isLoginModalOpen" [backdropDismiss]="false">
                        <ng-template>
                          <ion-header>
                            <ion-toolbar>
                              <ion-buttons slot="start">
                                <ion-button (click)="toggleModal('login', false)">Cancel</ion-button>
                              </ion-buttons>
                              <ion-title style="text-align: center;">Membership Setup</ion-title>
                              <ion-buttons slot="end">
                                <ion-button (click)="confirm($event)" [strong]="true">Confirm</ion-button>
                              </ion-buttons>
                            </ion-toolbar>
                          </ion-header>
                          <ion-content class="ion-padding">
                            <ion-item>
                              <ion-label position="stacked">Enter your last name</ion-label>
                              <ion-input type="text" placeholder="Your last name" [(ngModel)]="lastName"></ion-input>
                            </ion-item>
                            <ion-item>
                                <ion-label position="stacked">Enter your Member ID</ion-label>
                              <ion-input type="text" placeholder="Member ID" [(ngModel)]="memberId"></ion-input>
                            </ion-item>
                          </ion-content>
                        </ng-template>
                      </ion-modal>
                </div>
            </div>
            <div *ngIf="item['link']" (click)="handleLink(item['link'])" style="height: 100%; width: 100%">
              <div></div>
            </div>
        </div>
        <div class="item-info">
            <div class="item-name">
                <p [innerHTML]="item['name']"></p>
            </div>
            <!-- <br /> -->
        </div>
    </div>
</ng-template>
