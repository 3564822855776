<div class="container-fluid">
    <h1>Hours</h1>
    <div class="today" *ngIf="today">
        <p>Today</p>
        {{today.timeOpen}} - {{today.timeClose}}
    </div>
    <div class="week" *ngIf="formattedHours">
        <div *ngFor="let day of formattedHours" class="weekday">
            <span>
                {{day.dayName}}
            </span>
            <span>
                {{day.timeOpen}} - {{day.timeClose}}
            </span>
        </div>
    </div>
</div>