import { Component, OnInit } from '@angular/core';
import { GuruApiAppService } from '../guru-api/guru-api-app.service';
import { Subject, Subscription } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { LoadingService } from '../services/loading/loading.service';
import { OpeningHours } from '../guru-api/models/app-details.model';
import * as moment from 'moment';
interface HourDisplay {
  dayName: string
  timeOpen: string
  timeClose: string
}
@Component({
  selector: 'app-hours-page',
  templateUrl: './hours-page.component.html',
  styleUrls: ['./hours-page.component.scss']
})
export class HoursPageComponent implements OnInit {
  unsubscribe$: Subject<void> = new Subject<void>();
  hours: OpeningHours = null;
  today: HourDisplay = null;
  formattedHours: HourDisplay[] = null;
  constructor(private guruAppDetailService: GuruApiAppService, private loadingService: LoadingService) { }

  ngOnInit(): void {
    this.loadingService.loaderState
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(state => {
      if (!state.show) {
        this.loadHours()
      }
    });
  }
  loadHours(): void {
    const appDetails = this.guruAppDetailService.appDetails;
    if (appDetails.opening_hours) {
      this.hours = appDetails.opening_hours;
      this.formattedHours = this.formatHours();
      this.today = this.getTimeForToday();

      return;
    }
    this.guruAppDetailService.loadAppFullDetails()
    .pipe(takeUntil(this.unsubscribe$))
    .subscribe(data => {
      this.hours = data.opening_hours;
      this.guruAppDetailService.setAppDetails(data);
      this.formattedHours = this.formatHours();
      this.today = this.getTimeForToday();
    })
   
  }
  formatHours(): HourDisplay[] {
    return this.hours.normal.map(hx => {
      const d = moment().day(hx.day);
      const sTx = moment(hx.start_time, ['HH:mm:ss'])
      const eTx = moment(hx.end_time, ['HH:mm:ss'])
      return {
        dayName: d.format('dddd'),
        timeOpen: sTx.format('h:mm A'),
        timeClose: eTx.format('h:mm A')
      } as HourDisplay
    });

  }
  getTimeForToday(): HourDisplay {
    const currentDoW = moment().format('dddd');
    const filt =  this.formattedHours.filter(hd => hd.dayName == currentDoW)
    return filt[0]
  }
  // formatSpec

}
