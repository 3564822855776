import { Component, Input, OnChanges, ViewChild, OnDestroy, OnInit, ViewEncapsulation } from '@angular/core';
import { TourItemModel } from '../guru-api/models/tour-item.model';
import { StreamState } from '../services/audio/stream-state';
import { pipe, Subject, Subscription } from 'rxjs';
import { ActivatedRoute } from '@angular/router';
import { AudioService } from '../services/audio/audio.service';
import { GuruApiTourItemsService } from '../guru-api/guru-api-item.service';
import { GuruFirebaseService } from '../services/guru-firebase/guru-firebase.service';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import { PhotoModel } from '../guru-api/models/photo.model';
import { PROJECTION_TYPE, PanoViewer } from '@egjs/ngx-view360';
import { IonModal } from '@ionic/angular';
import {ImageModel} from '../guru-api/models/image.model';

@Component({
  selector: 'app-item-details',
  templateUrl: './item-details.component.html',
  styleUrls: ['./item-details.component.scss'],
  // encapsulation: ViewEncapsulation.None
})
export class ItemDetailsComponent implements OnInit, OnDestroy, OnChanges {
  @Input() stop?: TourItemModel;
  itemId?: number;
  itemObj: TourItemModel;
  state: StreamState;
  showGallery = false;
  showVideos = false;
  unsubscribe$: Subject<void> = new Subject<void>();
  imgSrc: string = ''
  openModal: boolean = false;
  @ViewChild(IonModal) modal: IonModal;

  traceTimeSpent: any;
  btnList: Object[];


  set item(value: TourItemModel) {
    this.itemObj = value;

    if (this.audioService.tourItemId !== this.item.id) {
      if (this.item.audio) {
        this.audioService.prepareStream(this.item.audio.audio).subscribe(events => {
        });
        this.audioService.tourItemId = this.item.id;
      }
    }
  }

  get item(): TourItemModel {
    return this.itemObj;
  }

  constructor(private route: ActivatedRoute,
              private audioService: AudioService,
              private guruApiItemService: GuruApiTourItemsService,
              private guruFirebaseService: GuruFirebaseService,
              private loadingService: LoadingService
  ) {
    this.audioService.resetState();
    this.audioService.getState().subscribe(state => {
      this.state = state;
    });
    
  }

  ngOnInit(): void {
    this.loadingService.appState.subscribe(state => {
      if (state) {
        this.traceTimeSpent = this.guruFirebaseService.performance.trace('Time Spent On Item Details Page');
        this.traceTimeSpent.start();
        if (!this.stop) {
          this.route.paramMap
            .pipe(takeUntil(this.unsubscribe$))
            .subscribe(params => {
              if (this.item && this.audioService.tourItemId !== this.item.id) {
                this.audioService.stop();
                this.audioService.resetState();
              }

              this.itemId = Number(params.get('itemId'));
              this.loadItem();
            });
        } else {
          if (this.stop && this.audioService.tourItemId !== this.stop.id) {
            this.audioService.stop();
            this.audioService.resetState();
          }
          this.item = this.stop;
        }
      }
    }, error => {
      console.log('error', error);
    })
  }

  ngOnChanges(): void {
    if (this.stop) {
      this.audioService.stop();
      this.audioService.resetState();
      this.item = this.stop;
    }
  }

  ngOnDestroy(): void {
    this.traceTimeSpent.stop();
    this.unsubscribe$.next();
    this.unsubscribe$.complete();
    if (this.audioService) {
      console.log("destroy")

      this.audioService.stop();
      this.audioService.resetState();
    }
  }

  loadItem(): void {
    const traceItemDetailsLoad = this.guruFirebaseService.performance.trace('Item Details Load')
    traceItemDetailsLoad.start();

    this.guruApiItemService.loadTourItem(this.itemId)
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
      data => {
        this.item = data;
        this.guruFirebaseService.logVisitTourItemPage(this.item);
        this.traceTimeSpent.putAttribute('Name', this.item.name);
        traceItemDetailsLoad.putAttribute('Name', this.item.name);
        traceItemDetailsLoad.stop();
        this.btnList = this.loadbuttonList()
      },
      error => {
        if (error.error.detail === 'Invalid page.') {
          console.log('error', error);
        }
        traceItemDetailsLoad.stop();
      });
  }

  getName(): string {
    return this.item.name;
  }

  play(): void {
    if (this.state.canplay) {
      this.audioService.play();
    } else {
      this.audioService.playStream(this.item.audio.audio).subscribe(events => {
      });
    }
  }

  pause(): void {
    this.audioService.pause();
  }

  onSliderChangeEnd(change): void {
    this.audioService.seekTo(change.value);
  }

  toggleGallery(): void{
    this.showVideos = false;
    this.showGallery = !this.showGallery;
  }
  toggleVideos(): void{
    this.showGallery = false;
    this.showVideos = !this.showVideos;
  }
  get360s(not360: boolean = false): PhotoModel[] {
    return this.item.photos.filter((vx) => {
            console.log(vx)
      if (not360) {
        return !vx.is_360_panorama_photo
      }
      return vx.is_360_panorama_photo
    })
  }
  
  imageList(): ImageModel[] {
    const photos = this.get360s(true).map((ph) => {
      return ph.image
    })
    console.log(photos)
    return [this.item.image, ...photos]
  }
  loadbuttonList(): Object[] {

    var buttons =  [
      // {
      //   name: "Description",
      //   icon: "fas fa-file-alt"
      // },
      {
        name: "Videos",
        icon: "fab fa-youtube",
      },
      // {
      //   name: "Location",
      //   icon: "fas fa-map"
      // },
      // {
      //   name: "ARs",
      //   icon: "fab fa-unity"
      // },
      // {
      //   name: "More",
      //   icon: "fas fa-ellipsis-h"
      // }
    ]
    if (this.item.videos.length == 0) {
      buttons.shift()
    }
    if (this.item.ar_sdks.length> 0) {
      buttons.push(
        {
          name: "ARs",
          icon: "fab fa-unity"
        } 
      )
    }
    
    if (this.get360s().length > 0) {
      buttons.push(
        {
          name: "360",
          icon: "fab fa-globe"
        } 
      )
    }
    return buttons
  }
  buttonHandler(buttonName: string) {
    switch (buttonName) {
      case "Videos": {
        window.open(this.item.videos[0]?.video.video)
        break;
      }
      case "360": {
        this.setViewer(this.get360s()[0].image.full)
        break;
      }
    }
  }
  modalOpen($event) {
    console.log($event)
    console.log(document.getElementById("panoViewer"))
    const panoViewer = new PanoViewer(
      document.getElementById("panoViewer"),
      {
        // image: this.imgSrc,
        projectionType: PROJECTION_TYPE.PANORAMA
      },
    );
    const sensorRes = panoViewer.enableSensor().then((val) => {
      console.log(val)
      panoViewer.setImage(this.imgSrc)
      panoViewer.updateViewportDimensions()
    }).catch((val) => {
      console.log("catch", val)
    })
    console.log(sensorRes)
    
  }
  setViewer(url: string) {
    const panoViewer = new PanoViewer(document.getElementById("pano-holder"), {}).enableSensor().catch(err => console.log(err))
    console.log("pano", panoViewer)
    this.openModal = true

    this.imgSrc = url
  }
  closeViewer() {
    this.openModal = false
  }
}
