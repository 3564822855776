<!--<app-about-us-page></app-about-us-page>-->
<!--<app-language-page></app-language-page>-->
<!--<app-language-page></app-language-page>-->
<!--<app-more-menu></app-more-menu>-->
<!--<app-settings-page></app-settings-page>-->
<!--<app-home-page></app-home-page>-->
<!--<app-settings-page></app-settings-page>-->
<!--<app-tour-intro-page></app-tour-intro-page>-->
<div class="app-content" [ngStyle]="getState() && {'filter' : 'blur(5px)', 'height' : '100%'}">
  <app-loading-page></app-loading-page>
  <app-top-nav [isWeb]="isWeb()">
    <router-outlet (activate)="onActivate($event, outlet)" #outlet></router-outlet>
  </app-top-nav>
</div>

<app-more-menu></app-more-menu>
<app-bottom-menu [active]="activePageURL"></app-bottom-menu>
<!--<app-map-page></app-map-page>-->
<!--<app-map-list></app-map-list>-->
<!--<app-item-list></app-item-list>-->
