import { Component, OnInit } from '@angular/core';
import { EventItemModel, EventResponseModel } from '../guru-api/models/events.model';
import { Subject, Subscription } from 'rxjs';
import { GuruApiEventService } from '../guru-api/guru-api-events.service';
import { LoadingService } from '../services/loading/loading.service';
import { takeUntil } from 'rxjs/operators';
import {Router, ActivatedRoute} from '@angular/router';
import { GuruApiAppService, } from '../guru-api/guru-api-app.service';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
import * as moment from "moment";
import * as ics from "ics";
import * as FileSaver from 'file-saver';
import { Browser } from '@capacitor/browser';
import Calendar from '../plugins/calendar';
import { GuruApiEventDetailService } from '../guru-api/guru-api-event-detail.service';
import { AppLauncher } from '@capacitor/app-launcher';
import { Toast } from '@capacitor/toast';

@Component({
  selector: 'app-events-item-page',
  templateUrl: './events-item-page.component.html',
  styleUrls: ['./events-item-page.component.scss']
})
export class EventsItemPageComponent implements OnInit {
  eventId: number
  startTime: number
  eventDef: EventResponseModel
  unsubscribe$: Subject<void> = new Subject<void>();
  saving: boolean = false;
  constructor(private route: ActivatedRoute,
    private guruApiEventService: GuruApiEventService,
    private guruApiEventDetailService: GuruApiEventDetailService,
    private guruApiAppService: GuruApiAppService,
    private router: Router,
    private loadingService: LoadingService) { }
  
  ngOnInit(): void {
    this.loadingService.appState.subscribe(state => {
      if (state) {
        
        this.route.paramMap.subscribe(params => {
          this.eventId = Number(params.get('id'));
          // this.tourStopIndex = Number(params.get('tourStopIndex'));
          this.loadData();
        });
        this.route.queryParamMap.subscribe(queryParams =>{
          console.log(this.route.queryParams)
          this.startTime = Number(queryParams.get('start_time'));
          if (this.eventId) {
            this.loadData();
          }
        })
      }
    })
  }
  loadData(): void {
    if (!this.eventId || !this.startTime) {
      return
    }
    const events = this.guruApiEventService.getEvent(this.eventId);
    if (events) {
      const event = events.filter((ev: EventResponseModel) => {
        return ev.start_time == this.startTime
      })[0]
      console.log("ev:", event)
      if (event) {
        this.eventDef = event;
        this.loadDetailData()
        return;
      }
    }
    
      this.guruApiEventService.loadEvents()
      .pipe(takeUntil(this.unsubscribe$))
      .subscribe(
        data => {
          this.guruApiEventService.setEvents(data.objects);
          const events = this.guruApiEventService.getEvent(this.eventId);
          console.log("events: ", events)
          const event= events.filter((ev: EventResponseModel) => {
            return ev.start_time == this.startTime
          })[0]

          console.log("ev: ", event)
          this.eventDef = event
          this.loadDetailData()
        }
      )
    
  }
  loadDetailData(): void {
    this.guruApiEventDetailService.loadEvent(this.eventId)
          .pipe(takeUntil(this.unsubscribe$))
          .subscribe(
            data => {
              console.log(data)
              this.eventDef.event.details = data;
            }
          )
  }
  formatDisplayDate(): string {
    return moment.unix(this.eventDef.start_time).format("MMM D YYYY")
  }
  formatEventDuration(): string {
    const startDate = moment.unix(this.eventDef.start_time);
    const endDate = moment.unix(this.eventDef.end_time)
    return startDate.format("h:mm A") + " - " + endDate.format("h:mm A")
  }
  async addToCal() {
    const startDate = moment.unix(this.eventDef.start_time);
    const endDate = moment.unix(this.eventDef.end_time)
    const eventDuration = moment.duration(endDate.diff(startDate))
    const event = {
      productId: new Date().getMilliseconds() + "-event",
      uid: new Date().getMilliseconds() + "-event@sch.com",
      start: [
        startDate.year(),
        startDate.month(),
        startDate.day(),
        startDate.hour(),
        startDate.minute(),
      ] as ics.DateArray,
      duration: {
        hours: eventDuration.hours(),
        minutes: eventDuration.minutes()
      },title: this.eventDef.event.name
    }
    console.log("start: " + startDate.toString());
    console.log(startDate.valueOf());
    if (this.guruApiAppService.deviceInfo == "android") {
      console.log(startDate.valueOf());
      console.log(startDate.valueOf());
      var d = document.createElement('div');
      d.innerHTML = this.eventDef.event.details.description
      const eventData = {
        startTime: startDate.valueOf(),
        endTime: endDate.valueOf(),
        title: this.eventDef.event.name,
        description: (d.textContent || d.innerHTML)
      }
      const { value } = await Calendar.createCalendarEvent({ value: eventData });
      console.log(value)
      return
    } else if (this.guruApiAppService.deviceInfo == "ios") {
      console.log(startDate.valueOf());
      console.log(startDate.valueOf());
      var d = document.createElement('div');
      d.innerHTML = this.eventDef.event.details.description
      const eventData = {
        startTime: startDate.valueOf(),
        endTime: endDate.valueOf(),
        title: this.eventDef.event.name,
        description: (d.textContent || d.innerHTML)
      }
      this.loadingService.show();
      const { value } = await Calendar.createCalendarEvent({ value: eventData });
      console.log(value)
      this.loadingService.hide()
      if (value == "created") {
        Toast.show({
          text: "Event Created!"
        })
      }
      return
    }
    ics.createEvent(event, (error, evVal) => {
      if (error) {
        return
      }
    
      const filename = "new-event.ics"
      console.log("ev val: " + evVal)
      const file = new File([evVal], filename, { type: 'text/calendar' })
      // var blob = new Blob([value], {type: "text/calendar"})
      // // FileSaver.saveAs(blob, "event.ics")
      // var fileURL = URL.createObjectURL(blob);
      // console.log(blob)
      if (this.guruApiAppService.deviceInfo != "web") {
        // const url = URL.createObjectURL(file);
        console.log("file: ", file)
        // trying to assign the file URL to a window could cause cross-site
        // issues so this is a workaround using HTML5
        // const anchor = document.createElement('a');
        // anchor.href = url;
        // anchor.download = filename;

        // document.body.appendChild(anchor);
        // anchor.click();
        // document.body.removeChild(anchor);

        // URL.revokeObjectURL(url);
        // await AppLauncher
        // console.log("webcal://data:text/calendar;charset=utf8," + escape(evVal))
        // await AppLauncher.openUrl({ url:  "webcal://data:text/calendar;charset=utf8," + escape(evVal)});
        // console.log("opened")
      } else {
        window.open( "webcal://data:text/calendar;charset=utf8," + escape(evVal));

      }
      // window.open(fileURL)
    })
  }
  getImage(): string {
    return this.eventDef.event.default_image.original
    // return this.eventDef.event.default_image.square_retina??this.eventDef.event.default_image.normal_retina
  }
}
